header.composition_subhead {
  padding-top: $main-margin;

  .bio {
    @include paragraph-text;
  }

  @include breakpoint(medium up) {
    padding-top: 2 * $main-margin;
    padding-bottom: $main-margin;
  }
}