header.page_header {
  font-family: $secondary-font-family;
  padding-top: $main-margin;
  a, span.link {
    @include strong-link-item;
  }
  nav {
    display: flex;
    align-items: center;
    li {
      display: inline-block;
    }
    a {
      display: inline-block;
    }

    ul.home_links {
      min-width: 192px;
      .spco_link {
        padding-right: .4rem;
         a {
           margin-top: 7px;
           svg {
             width: 83px;
             height: 32px;
            }
         }
      }
      .home_link {
        border-left: 1px solid $light-gray;
        padding-left: .5rem;
        svg {
          width: 93px;
          height: 32px;
         }
      }
    }
  }
}

ul#main_nav {
  margin-left: $main-margin;
  display: none;
  li {
    display: inline;
    a:hover {
      color: $red;
    }
  }
  &.programs li.programs,
  &.live_streams li.live_streams,
  &.compositions li.compositions,
  &.collections li.collections,
  &.features li.features {
    a {
      color: $red;
    }
  }
  a {
    display: inline-block;
    margin-right: .5rem;
    padding: .4rem .5rem .3rem;
    line-height: 1rem;
  }

  li.live_streams {
    a {
      border: 1px solid $border-light-medium;
      border-radius: 1px;
      vertical-align: middle;
      &:hover {
        background-color: $field-background;
      }
    }
  }
}

.user_menu {
  display: flex;
  align-items: center;
}

.user_nav {
  display: none;
  .large_link {
    display: none;
  }
  a:hover {
    color: $red;
  }
}

.user_menu_toggle {
  cursor: pointer;
  line-height: 1rem;
  svg {
    vertical-align: middle;
    display: inline-block;
    margin-left: .5rem;
  }
}

.user_dropdown_popover {
  .Popover-body, .Popover-tipShape {
    background: $spco-white;
    fill: $spco-white;
  }
}

.user_dropdown {
  font-family: $secondary-font-family;
  a {
    color: $dark-gray;
    display: block;
    padding: .3rem 1rem;
    &:hover {
      color: $red;
    }
  }
}

@include breakpoint(mediumlarge up) {

  .bm-burger-button {
    display: none;
  }
  #main_nav,
  .user_nav {
    display: inline!important;
  }
  .user_nav {
    .large_link {
      display: block;
      margin-right: 1.5rem;
      .icons {
        vertical-align: middle;
      }
    }
    .small_link {display: none;}
    li {
      display: inline-block!important;
      &:first-child {
        margin-right: 1rem;
      }
    }
  }
}
.bm-menu {
  #main_nav,
  .user_nav {
    display: block;
    li {
      display: block;
    }
  }
  .user_nav {
    li.user_name {
      color: $white;
      @include small-caps;
      font-weight: 900!important;
      letter-spacing: .05rem;
      padding: .4rem .5rem .3rem;
    }
  }
}

@include breakpoint(large up) {
  ul#main_nav {
    margin-left: 2 * $main-margin;
    a {
      margin-right: 1rem;
    }
  }
}

// Prevent orphan on main_nav at these breakpoints
@media screen and (min-width: 800px) and (max-width: 1130px) {
    ul#main_nav {
      max-width: 460px;
    }
}
