header.home_header {
  margin: 1rem 0;

  h2 {
    font-weight: normal;
    font-family: $secondary-font-family;
    font-style: italic;
    font-size: 1rem;
  }

  @include breakpoint(medium up) {
    margin: 4rem 0;
    h2 {
      font-size: 1.3rem;
    }
  }
}

.home_highlight {
  margin-top: 1rem;
  p {
    margin-bottom: 0;
  }
  ul {
    margin-left: 2rem;
    > ul {
      margin-left: 1em;
    }
  }

  li {
    list-style-type: disc;
  }
}
.home_video {
  margin-top: 1rem;

}

.home_lists {
  section {
    clear: left;
    position: relative;
    padding-top: 1rem;
    margin-bottom: 2rem;
    h2 {
      @include small-caps;
      font-family: $secondary-font-family;
      font-size: 1.1rem;
      padding-bottom: .5rem;
      border-bottom: 1px solid $border-light-gray;
      a {
        color: $white;
      }
    }
    a.button {
      position: absolute;
      right: 0;
      top: .5rem;
      padding: .2rem .5rem;
      border-radius: 3px;
    }
  }

  @include breakpoint(medium up) {
    h2 {
      font-size: 1.2rem;
      padding-bottom: 1rem;
    }
  }
}

ul.home_list {
  padding-top: 1rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5em;

  > li {
    cursor: pointer;
  }
  .image {
    aspect-ratio: 48 / 27; // 1920 / 1080
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .title {
    text-align: center;
    margin-top: .5rem;
    .composer, .program_title {
      font-size: .9rem;
    }
  }
  .composer, .program_title {
    line-height: 1.1rem;
  }
  h4 {
    color: $red;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin-bottom: .1rem;
  }
  span {
    // line-height: 1.1rem;
    display: block;
  }
  @include breakpoint(medium up) {
    .title {

    }
    h4 {
      // font-size: 1.3rem;
    }
  }
}

@media print, screen and (min-width: 540px) {
  ul.home_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media print, screen and (min-width: 800px) {
  ul.home_list {
    // This limits medium screens to 3-wide, hiding some elements
    display: block;
    grid-template-columns: repeat(3, 1fr);
    columns: 3;
    overflow: hidden;
    li {
      break-after: column;
      break-inside: avoid;
    }
  }
}
@media print, screen and (min-width: 1024px) {
  ul.home_list {
    columns: auto;
    display: grid;
    overflow: auto;
    grid-template-columns: repeat(4, 1fr);
  }
}

.live_stream_highlight {
  font-size: 1rem;
  margin: 1rem 0;
  padding: 1rem 0;

  .label {
    padding: .3rem .5rem .2rem;
    @include strong-link-item;
    font-family: $secondary-font-family;
    margin: .5rem 0 .5rem;
    border: 1px solid $border-light-medium;
    border-radius: 2px;
    vertical-align: middle;
    display: inline-block;
    font-size: .75rem;
  }

  .start {
    color: $white-fade;
    font-family: $secondary-font-family;
    font-size: .9rem;
  }
  img {
    border-radius: 3px;
    max-height: 150px;
  }
  .button {
    margin-top: 1rem;
    margin-bottom: 0;
    border-radius: 2px;
  }

  @include breakpoint(large up) {
    margin: 1rem 0 2rem;
    .start {
      font-size: 1.2rem;
    }
    img {
      max-height: auto;
    }
  }
}
.contribute_block {
  background-color: $spco-white;
  color: #222;
  border-left: 4px solid $red;
  border-radius: 3px;
  padding-bottom: $main-margin;
  padding-top: $main-margin;
  margin-top: $main-margin;
  .columns {

  }
  h5 {
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    margin-right: .5rem;
    margin-bottom: 0;
  }
  p {
    display: inline;
    color: darken($text-gray, 10%);
  }
  .button {
    margin-bottom: 0;
  }
}

.home_blocks {
  font-family: $secondary-font-family;
  > .columns {
    margin-bottom: 3rem;
    margin-top: 1rem;
    @include breakpoint(medium up) {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
  h2 {
    font-family: $secondary-font-family;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
    display: inline-block;
    border-bottom: 1px solid $border-light-medium;
    @include breakpoint(medium up) {
      padding-bottom: 1rem;
      padding-right: 2rem;
    }
  }
  h3 {
    font-family: $secondary-font-family;
    font-size: 1rem;
  }
  p {
    color: $white-fade-medium;
    font-size: .85rem;
    line-height: 1.1rem;
    margin-bottom: 1rem;
  }
  .button {
    text-transform: uppercase;
    font-weight: bold;
    font-size: .8rem;
  }
  svg {
    max-width: 180px;
  }
  svg.mpr {
    max-width: 160px;
  }
  a:hover svg {
   opacity: 80%;
  }
}