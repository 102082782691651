i.play_circle {
  $dim: 9px;
  width: $dim;
  height: $dim;
  border-radius: $dim;
  background-color: $red;
  display: inline-block;
  margin-right: .5rem;
  vertical-align: middle;
  margin-bottom: 3px;
}

.underwriter_logo {
  padding: 1rem 0;
  img {
    max-width: 120px;
    max-height: 30px;
    margin-right: 1rem;
  }
  p {
    @include thick-caps;
    font-family: $secondary-font-family;
    color: $medium-gray;
  }
}

.ical_link {
  a {
//    padding: .3rem .6rem .4rem;
    border-radius: 2px;
    font-size: .9rem;
  }
}
.calendar_links {
  margin-top: 2rem;
  a {
    line-height: 2rem!important;
    margin-right: $global-margin;
    margin-bottom: $global-margin;
  }
  a svg {
    width: 30px;
    height: 30px;
    vertical-align: top;
    display: inline-block;
    margin-right: .5rem;
    &.ical {
      margin-top: 3px;
      width: 24px;
      height: 24px;
    }
  }
}