ul.live_stream_list {
  li {
    cursor: pointer!important;
    padding-left: 1rem;
    padding-right: 1rem;
    &.status_C {
     h4 a {
       color: $white-fade;
     }
    }
  }
  .live_flag {
    margin-left: 2rem;
    padding: .2rem .5rem;
    line-height: 1.2rem;
    display: inline-block;
    border: 1px solid $border-light-medium;
    border-radius: 1px;
    background-color: $field-background;
    font-family: $secondary-font-family;
    @include small-caps;
    color: $white-fade;
    white-space: nowrap;
  }
}
.main_container.live_stream {
  border-bottom: 1px solid $border-light;
}

.print_link {
  a.button {
    margin-bottom: 0;
  }
}

iframe.chat {
  border: 0;
  width: 100%;
  height: 400px;
}

.stream_sponsors {
  padding-bottom: 1rem;
  li {
    display: inline-block;
    margin-right: 1rem;
  }
  p {
    @include thick-caps;
    font-family: $secondary-font-family;
    color: $medium-gray;
    margin-bottom: 0;
  }
}
.extra_sponsors {
  margin-top: $global-margin;
  font-size: .8rem;
  p {
    font-style: italic;
  }
  img {
    margin-bottom: $global-margin;
  }
  .nea_sponsor {
    svg {
      width: 80px;
    }
  }
  .msab_sponsor {
    img {
      width: 80px;
    }
  }
}
.stream_program_wrap {
  margin-bottom: 1rem;
  .program_performance {
    border-top: 1px solid $border-light;
    margin-bottom: .5rem;
    margin-top: .5rem;
    padding-top: .5rem;
    h3 {
      font-size: 1.1rem;
      margin-bottom: 0;
    }
    .premiere, .commission {
      font-family: $secondary-font-family!important;
      font-weight: bold;
      margin-left: 1rem;
      background-color: $border-light;
      padding: 1px .5rem;
    }
    strong, a {
      margin-right: .5rem;
    }
    span {
      font-size: .9rem;
    }
    .artist {
      margin: .5rem 0 .5rem 1rem;
      &.conductor {
      }
      em {
        font-style: normal;
        color: $white;
      }
      span {
        margin-left: .5rem;
        color: $white-fade-more;
      }
    }
  }

}

.main_container.live_stream {
  .theater_mode {
    .button {
      padding-bottom: .35rem;
      border-radius: 2px;
    }
    svg {
      width: 24px;
      height: 24px;
      vertical-align: top;
      display: inline-block;
      margin-top: -4px;
      margin-right: 0.5rem;
    }

  }
}
.row.live_stream_wrapper {
  .sidebar {
    transition: all 0.25s ease-out;
  }
  .live_stream.columns {
    transition: all 0.25s ease-out;
  }
  &.theater_mode {
    .sidebar {
      max-width: 0;
    }
    .live_stream.columns {
      flex: 0 0 100%!important;
      max-width: 100%!important;
    }
  }
}