/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 24px;
  height: 20px;
  right: 24px;
  top: 28px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $light-gray;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: $black;
  padding: 2.5em 1em 0;

  ul#main_nav {
    display: block;
    margin: 0;
    li {
      display: block;
    }
  }
  .user_nav {
    display: block;
    margin-top: .5rem;
  }
  a {
    padding: .8rem .5rem .6rem!important;
  }
  .live_streams a {
    padding: .5rem .5rem .5rem!important;
    margin-bottom: .3rem;
  }
}

/* Wrapper for item list */
nav.bm-item-list {
  display: block!important;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}