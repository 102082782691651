.button {
  font-family: $secondary-font-family;
  &.rounded {
    border-radius: 30px;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  &.strong {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
  }
  &.light {
    background-color: $light-gray;
    @include small-caps;
    font-size: .7rem;
    color: #454545;
  }
}
.share_buttons {
  margin-bottom: $global-margin;
  button {
    color: #fff;
    font-family: $secondary-font-family!important;
    font-weight: 600!important;
    margin-right: $global-margin;
    padding: 0 .5rem 0 .25rem!important;
    border-radius: 2px;
    font-size: .85rem!important;
    line-height: 1rem!important;
    &:hover {
      opacity: .9!important;
    }
    svg {
      vertical-align: middle;
    }
  }
  .fb {
    background-color: #3b5998!important;
  }
  .twitter {
    background-color: #00aced!important;
  }

}
.native_share {
  margin-bottom: $global-margin;
  button {
    background-color: transparent!important;
    color: $white-fade;
    border: 1px solid $white-fade;
    border-radius: 4px;
    padding: .3rem .6rem .5rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    svg {
      margin-right: .5rem;
      width: 24px;
      height: 24px;
    }
  }
}