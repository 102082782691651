ul.media_feature_list {
  li {
    cursor: pointer!important;
  }
  .date {
    display: inline;
  }
  .category {
    display: inline-block;
    margin-left: 1rem;
    @include small-caps;
    font-family: $secondary-font-family;
    color: $white-fade-medium;
  }
  &.image_grid {
    .category {
      display: none;
    }
  }
}

.feature_date {
  @include paragraph-text;
  color: $white-fade-medium;
  font-size: .9rem;
}

.feature_credits {
  font-size: .9rem;
  color: $white-fade;
}