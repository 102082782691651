/* genres, nationalities, etc */


ul.category_listing {
  li {
    padding: $half-margin $half-margin;
    cursor: pointer;
    @include hover-lighten;
    font-size: 1rem;
    border-bottom: 1px solid $border-light;
    a {
      color: $white;
      font-weight: bold;
    }

    @include breakpoint(medium up) {
      padding: $main-margin $main-margin;
      font-size: 1.2rem;
    }
  }
}