
#composition_nav {
  font-family: $secondary-font-family;
  font-weight: 900;
  margin-left: 0;
  margin-bottom: 0;
  li {
    display: inline;
  }
  a {
    @include thick-caps;
    display: inline-block;
    padding: .4rem .1rem;
    margin: 0 .4rem 0;
    border-bottom: 4px solid transparent;
    color: $medium-gray;
    font-size: .75rem;
    &.active {
      color: $red;
      border-bottom-color: $red;
    }
    &:hover {
      color: $red;
    }
  }

  @include breakpoint(large up) {
    a {
      margin: 0 .7rem 0;
    }
  }

}

#composition_nav_mobile {
  font-family: $secondary-font-family;
  > div {
    display: inline-block;
    border: 1px solid $border-light-medium;
    border-radius: 2px;
    padding: .2rem 1rem;
    text-align: left;
    min-width: 8rem;
    position: relative;
    padding-right: 1.6rem;
    svg {
      width: 12px;
      position: absolute;
      right: 10px;
      top: .5rem;
    }
  }
  margin-bottom: 1rem;
  strong {
    @include thick-caps;
    color: $medium-gray;
  }
}
.mobile_comp_popover {
  .Popover-body, .Popover-tipShape {
    font-family: $secondary-font-family;
    background-color: #000;
    fill: #000;

    a {
      font-size: 1rem;
      display: block;
      padding: .2rem .5rem;
      @include small-caps;
      color: $white;
      &:hover {
        color: $red;
      }
    }
  }
}

.view_tools {
  text-align: right;
  ul {
  }
  li {
    display: inline-block;
    width: 32px;
    height: 32px;
    padding: 4px;
    svg {
      width: 24px;
      height: 24px;
      color: white;
    }
  }
  li.active {
    background-color: white;
    svg {
      color: black;
    }
  }

}