header.program {
  h4.date {
    font-weight: normal;
    font-size: 1.2rem;
  }
}
.program_performances {
  margin-bottom: 3rem;
}
.related_features {
  display: flex;
  flex-wrap: wrap;
  margin-top: .5rem;
  h5 {
    margin-bottom: 0;
  }
}
.related_feature {
  margin-top: $global-margin;
  max-width: 200px;
  margin-right: 2 * $global-margin;
  img {
    max-width: 200px;
  }
  p {
    font-family: $secondary-font-family;
    color: $white-fade;
    display: block;
    font-size: .9rem;
    line-height: 1.2rem;
  }
  .related_title {
    line-height: 1.2rem;
    margin-top: .25rem;
  }
  strong {
    font-size: .9rem;
  }
}

.program_performance {
  margin-top: 2rem;
  h3 {
    font-size: 1.5rem;
    margin-bottom: .3rem;
    strong, a {
      padding-right: 1rem;
      display: inline-block;
    }
    a {
      cursor: pointer!important;
    }
    span {
      font-size: 1.2rem;
      font-weight: normal;
      display: inline-block;
    }
  }

  .composition_performance {
    margin-top: 0;
    .main_container {
      padding-bottom: 1rem;
    }
  }

}