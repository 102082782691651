/* conductor / composer / performer */

ul.person_listing {
  li.person {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include hover-lighten;
    a {
      display: block;
      color: $white;
      font-weight: bold;
    }

  }
  &.table_view {
    li.person {
      padding: $half-margin 0;
      border-bottom: 1px solid $border-light;
      font-size: 1rem;
    }
    .image {
      width: 107px;
    }
    img {
      height: 60px;
    }
    div {
      margin-right: .5rem;
    }
    @include breakpoint(medium up) {
      padding: $main-margin $main-margin;
      font-size: 1.2rem;
    }
  }
  &.grid_view {
    li.person {
      display: inline-block;
      width: 264px;
      height: 218px;
      margin-right: 1.5rem;
      margin-bottom: 2rem;
      border-bottom: none;

      a {
        line-height: 1rem;
      }
      .title {
        text-align: center;
        margin-top: .5rem;
        .year {
          font-size: .8rem;
        }
      }
      .image {
        height: 150px;
        width: auto;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}