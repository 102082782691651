form {
  [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea  {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2.8rem;
  }

  .input {
    font-family: $secondary-font-family;

    .error {
      font-weight: bold;
      color: get-color(alert);
      margin-bottom: 1rem;
      display: block;
    }

    &.with_errors {
      [type='text'], [type='password'], [type='email'] {
        background-color: $field-background-invalid!important;
        margin-bottom: .3rem;
      }
    }
  }

  .callout {
    font-family: $secondary-font-family;
    &.alert {
      background-color: rgba(#f7e4e1, 0.8);
      border: none;
      font-weight: bold;
      margin-top: 2rem;
    }
  }
  .note {
    font-family: $secondary-font-family;
    font-size: .9rem;
  }

  .input.checkbox {
    input {
      margin: 0;
    }
    label {
      color: $white;
      line-height: 1.1rem;
      font-family: $secondary-font-family;
    }
  }
  /* Hide labels except from IE, where placeholders disappear */
  &.hide_labels {
    label {
      display: none;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        color: $white-fade;
      }
    }
    .checkbox label {
      display: inline;
    }
  }

}