.spinner {
  margin: 10px auto 0;
  width: 100px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: rgba(255,255,255, 0.6);
  margin: 0 5px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}


.spinner_alt {
  margin: 100px auto;
  display: inline-block;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner_alt > div {
  background-color: $red;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right: 3px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner_alt .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner_alt .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner_alt .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner_alt .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

$spinkit-size: 100%;
$spinkit-spinner-margin: 0;
$spinkit-spinner-color: #ffffff;

.sk-double-bounce {
  width: $spinkit-size;
  height: $spinkit-size;
  position: relative;
  margin: $spinkit-spinner-margin;

  .sk-child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $spinkit-spinner-color;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-doubleBounce 2.0s infinite ease-in-out;
  }

  .sk-double-bounce2 {
    animation-delay: -1.0s;
  }
}

@keyframes sk-doubleBounce {
  0%, 100% { transform: scale(0) }
  50% { transform: scale(1.0) }
}