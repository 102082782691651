.long_description {
  @include paragraph-text;
  font-size: .9rem;
  margin-bottom: 1rem;
  h3 {
    font-size: 1.1rem;
  }
  /* Work around react-truncate */
   p {
    @include paragraph-text;
  }
  @include breakpoint(medium up) {
    font-size: 1rem;
  }
  ul, ol {
    margin-left: 1rem;
  }
}
