.page_top {
  background-color: $black;
  > .row {
    background: url(../images/header_bg.jpg) no-repeat left top;
    background-size: cover;
  }
}
main {
}
.page_label {
  font-family: $secondary-font-family;
  color: $red;
  text-transform: uppercase;
  font-size: .9rem;
  margin: .5rem 0 1rem;
  .breadcrumb {
    display: inline-block;
    margin-left: .5rem;
    a {
      margin-left: .5rem;
    }
  }
}

#footer {
  color: $medium-gray;
  margin-top: 2rem;
  padding-bottom: 80px; // For player
  font-size: .8rem;
  font-family: $secondary-font-family;
  a {
    color: $medium-gray;
    &:hover {
      text-decoration: underline;
    }
  }
  a.link {
    color: $red;
    display: inline-block;
    margin-left: .5rem;
    font-weight: bold;
  }
}

.flash_wrapper {
  position: fixed;
  top: 1rem;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  z-index: 200;

  &.hidden {
    opacity: 0;
  }
}

.flash {
  padding: 1rem;
  background-color: rgba(yellow, 0.75);
  color: $dark-gray;
  font-family: $secondary-font-family;
  font-weight: bold;
  text-align: center;
}