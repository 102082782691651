header.image_header {
  padding-bottom: 1rem;
  .image {
    height: 200px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
  .image_credit {
    color: $white-fade-medium;
    font-size: .9rem;
    font-family: $secondary-font-family;
  }
  h1 {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .commission {
    float: right;
    border: 1px solid $border-light-gray;
    padding: 3px 6px;
    font-family: $secondary-font-family;
    text-transform: uppercase;
    font-weight: bold;
  }
  .composer {
    margin-bottom: 1rem;
    a {
      font-size: 1rem;
      color: $white;
    }
    &.byline {
      font-size: 1.1rem;
      color: $white-fade;
    }
    .composer_year {
      color: $white-fade;
      margin-left: .5rem;
      font-size: 1.1rem;
    }
    .arranger {
      font-size: 1rem;
      color: $white-fade-medium;
    }
  }
  .program_note {
    margin-bottom: 1rem;
    .byline {
      font-size: .9rem;
      font-style: italic;
    }
  }
  .header_image {
    margin-bottom: 1rem;
  }
  @include breakpoint(medium up) {
    h1 {
      font-size: 1.5rem;
    }
    .composer {
      font-size: 1.5rem;
    }
  }
}

.composition_performance {
  margin-top: 2rem;
  .header_row {
    position: relative;
    border-top: 1px solid $border-light;

    .dates {
      display: inline-block;
      @include grid-column-gutter($gutters: $grid-column-gutter); // Handles breakpoints on l/r padding
      padding-top: .5rem;
      padding-bottom: .5rem;
      line-height: 1rem;
      top: -1.1rem;
      position: absolute;
      @include small-caps;
      font-weight: 900;
      background-color: $spco-body;
      font-family: $secondary-font-family;
      color: $red;
      span.premiere {
        display: inline-block;
        margin-left: 1rem;
        padding: 1px .3rem;
        background-color: $border-light;
        color: $white-fade;
      }
    }
  }


/*  > .columns {
    border-top: 1px solid $border-light;
  }*/
  .sidebar {
    padding-top: 1.5rem;
    position: relative;
    font-family: $secondary-font-family;
    .wrap {
      margin-left: 2rem;
      margin-right: 1rem;
    }

    .label {
      @include thick-caps;
      color: $white-fade-more;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .musician {
      a {
        color: $white;
        border-bottom: 1px solid $white-fade-more;
      }
      &.conductor a {
      }
      em {
        font-style: normal;
        color: $white-fade-more;
      }
    }
    .sponsor svg {
      max-width: 180px;
    }
  }
  .main_container {
    > div {
      padding: 1rem;
    }
  }
  @include breakpoint(medium up) {
    .main_container {
      border-right: 1px solid $border-light;
      border-left: 1px solid $border-light;
      > div {
/*        padding-bottom: 4rem;*/
      }
    }
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.rights_message {
  color: $white-fade-more;
}
ul.movements {
  li {
    padding-bottom: .5rem;
  }
//  .playing_around {
//    width: 39px;
//    height: 39px;
//    position: absolute;
//    left: 0;
//  }
//
//  li {
//    .playing_around {
//      -webkit-animation:spin 6s linear infinite;
//       -moz-animation:spin 6s linear infinite;
//       animation:spin 6s linear infinite;
//     }
//    &.paused {
//      .playing_around {
//        -webkit-animation-play-state: paused;
//        -moz-animation-play-state: paused;
//        animation-play-state: paused;
//      }
//    }
//  }
  strong {
    font-size: 1rem;
    font-weight: normal;
  }
  .duration {
    text-align: right;
    font-family: $plain-font-family;
    color: $white-fade;
    font-size: .9rem;
    padding-left: .3rem;
  }
}
.play_buttons {
  font-size: .9rem;
  margin-top: .2rem;
  .button {
    padding: .4rem .6rem .5rem;
    border-radius: 2px;
    font-size: .85rem;
    line-height: 1rem;
  }

  svg {
    margin-right: .5rem;
    vertical-align: top;
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  .queue {
    border-color: $border-light-medium;
    margin-left: 1rem;
    color: $white;
    background: transparent;
    &:hover {
      color: $white;
      background-color: $field-background;
      border-color: $border-light-medium;
    }
  }

}

.video_preview, .video_player {
  margin-bottom: 1rem;
}

.video_preview {
  cursor: pointer;
  &.fetch_error {
    cursor: default;

    .play_icon {
      display: none;
    }
  }
  .error {
    margin-top: 1rem;
    font-size: .9rem;
    font-weight: bold;
    text-align: center;
  }
}
.captions_note {
  font-size: .8rem;
  text-align: center;
  margin-top: .5rem;
  strong {
    display: inline-block;
    padding: 0px 3px;
    line-height: 1rem;
    font-size: .7rem;
    margin: 0 .25rem;
    background-color: $white;
    color: $black;
    font-family: $secondary-font-family;
  }
}

.video_thumbnail {
  position: relative;
  min-height: 80px;
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .play_icon {
    border: 2px solid #fff;
    height: 64px;
    width: 64px;
    border-radius: 64px;
    padding: 18px;
    background-color: rgba(0,0,0,0.3);
    &:hover {
    }
  }
  &:hover {
    .overlay {
      background-color: rgba(0,0,0,0.0);
    }
    .play_icon {
      background-color: rgba(0,0,0,0.4);
    }
  }
}

.composition_video {
  .watch_link {
    color: $white;
    &:hover {
      color: $red
    }
  }
  &.full_program_video {
    margin-top: $global-margin;
  }
}
.credit {
  margin-top: 1rem;
  font-size: .9rem;
  color: $white-fade-medium;
}