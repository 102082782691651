html {
  height: 100%;
}

body.session {
  min-height: 100%;
  background: $black url(../images/hall_banner_bg.jpg) no-repeat center top;
  background-size: cover;
}

.session_page {
  align-items: center;
  justify-content: center;
  margin-bottom: 8rem;

  .session_header {
    margin-top: 2rem;
    text-align: center;
    margin-bottom: 2rem;

    .logos {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      > div {
        width: 50%;
        svg {
          width: 100%;
        }
      }
      > :first-child {
        padding-right: 2rem;
        border-right: 1px solid $white;
      }
      > :last-child  {
        margin-left: 1rem;
        padding-left: 1rem;
      }
    }

    .logo {
    }

    .library {
    }

  }

  .fb_login {
    margin-bottom: 1rem;
    text-align: center;
    svg {
      vertical-align: bottom;
      margin-right: 1rem;
    }
  }
  .apple_login {
    text-align: center;
    #appleid-signin {
      display: inline-block;
      border-radius: 10rem!important;

      width: 302px;
    }
    .button {
      width: 300px;
      height: 55px;
      border-radius: 10rem!important;
      overflow: hidden;
      background-color: black;
      svg {
        vertical-align: bottom;
        margin-right: 1rem;
      }
    }
  }
  .kep-login-facebook {
    padding: 1rem 2rem;
    border-radius: 10rem!important;

    i {
      font-size: 1.4rem;
      vertical-align: middle;
      margin-right: 1rem;
    }
  }

  .or_line {
    font-family: $secondary-font-family;
    text-transform: uppercase;
    text-align: center;
    padding: 2rem 0;
    .columns {
      border-top: 1px solid rgba($spco-white, .5);
    }
    .or {
      border: none;
      padding: 0 1rem;
      line-height: 1rem;
      margin-top: -.5rem;
    }
  }
  .input input {
    font-size: 1.2rem;
  }
  .submit_section {
    margin-top: 2rem;
    text-align: center;
  }

  .input.password input {
    margin-bottom: .5rem;
  }
  .forgot_password {
    text-align: right;
    font-size: .9rem;
    a {
      font-family: $secondary-font-family;
      color: $light-gray;
      text-decoration: underline;
    }
  }
  .login_note {
    margin-bottom: 1rem;
  }
  .extra_links {
    font-family: $secondary-font-family;
    margin-top: 2rem;
    a {
      color: $white;
      font-weight: bold;
      text-decoration: underline;
    }
  }
  @include breakpoint(medium up) {
    .login_note {
      margin-bottom: 2rem;
    }
  .session_header {
    margin-top: 10rem;
  }
}

}